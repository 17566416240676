/* button */
.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 0 5px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 500;
}
.green {
  background-color: white;
  color: black;
  border: 1px solid #008cba;
}

.green:hover {
  background-color: #008cba;
  color: white;
}

.red {
  background-color: white;
  color: black;
  border: 1px solid #f44336;
}
.red:hover {
  background-color: #f44336;
  color: white;
}
