html,
body {
  overflow: hidden;
  height: 100vh;
  padding: 0;
  margin: 0;
}
.layout {
  display: grid;
  height: 100vh;
  grid-template-areas: 'header header' 'aside_top content';
  grid-template-rows: 60px 1fr;
  grid-template-columns: minmax(224px, 224px) minmax(820px, 1fr);
  overflow-y: hidden;
  overflow-x: auto;
}
.header {
  grid-area: header;
  padding: 0 20px;
  transition: all 0.3s linear 0s;
}
.aside_top {
  position: relative;
  grid-area: aside_top;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #f3f3f3;
  transition: all 0.3s linear 0s;
}

.content {
  grid-area: content;
  padding: 35px;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}
.heaer_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.tit_h2 {
  flex: 1;
  text-align: left;
  color: #e7e7e7;
  text-transform: uppercase;
}
.profile {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #e7e7e7;
  border: 1px solid #ccc;
}
.profile:hover {
  border: 1px solid #ccc;
  background-color: white;
}
.profile a {
  display: block;
  width: 40px;
  height: 40px;
  background: url('./logout.png') 50% 50% no-repeat;
  background-size: 25px;
  border-radius: 100%;
  display: block;
  padding: 0;
  margin: 0;
  opacity: 0.5;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.aside_top ul {
  border-top: 1px solid #ddd;
}
.aside_top ul li {
  border-bottom: 1px solid #e7e7e7;
}
.aside_top ul li a {
  display: block;
  height: 50px;
  color: #e7e7e7;
  font-size: 1rem;
  font-weight: 500;
  line-height: 50px;
  padding: 0 20px;
  margin: 0;
}
.aside_top ul li a:hover {
  color: #009cff;
  background-color: white;
  opacity: 0.9;
  transform: scale(1);
  transition: all 0.3s linear 0s;
}

.our-team {
  padding: 30px 0 0;
  background-color: #f1f1f1;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.lightMod .our-team {
  background-color: #fff;
}

.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: '';
  width: 100%;
  height: 0;
  border-radius: 50%;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team .picture img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.our-team .name {
  display: block;
  font-size: 18px;
  color: #4e5052;
  text-transform: uppercase;
}

/* scrollbar style */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.aside_bottom {
  position: fixed;
  bottom: 20px;
  left: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
  border-radius: 8px;
  padding: 5px 10px 0;
}
.themControll a {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: red;
  border-radius: 100%;
  margin: 5px;
}
.themeTitle {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  padding: 0 16px;
  margin: 0;
}

/* darkMod */
#darkMod {
  background-color: #212121;
  background-color: var(--mdc-theme-primary, #212121);
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
}
.darkMod .header {
  background-color: #212121;
  background-color: var(--mdc-theme-primary, #212121);
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
}
.darkMod .aside_top {
  background-color: #212121;
  background-color: var(--mdc-theme-primary, #212121);
}
.darkMod .our-team .picture::before {
  background-color: #212121;
  background-color: var(--mdc-theme-primary, #212121);
}
.darkMod .our-team .picture::after {
  background-color: #212121;
  background-color: var(--mdc-theme-primary, #212121);
}
/* end darkMod */

#lightMod {
  background-color: #f3f3f3;
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
}
.lightMod .header {
  background-color: #ddd;
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
}
.lightMod .aside_top {
  background: #f3f3f3;
}
.lightMod .our-team .picture::before {
  background: #ddd;
  opacity: 0.7;
}
.lightMod .our-team .picture::after {
  background: #f3f3f3;
}
.lightMod .tit_h2 {
  color: #616161;
}
.lightMod .aside_top ul li a {
  color: #616161;
}
/* PinkMod */
#pinkMod {
  border-radius: 100%;
  background: linear-gradient(90deg, #ff4b2b, #ff416c);
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
}
.pinkMod .header {
  background: linear-gradient(90deg, #ff4b2b, #ff416c);
}
.pinkMod .aside_top {
  background: linear-gradient(90deg, #ff4b2b, #ff416c);
}
.pinkMod .our-team .picture::before {
  background: linear-gradient(90deg, #ff4b2b, #ff416c);
}
.pinkMod .our-team .picture::after {
  background: linear-gradient(90deg, #ff4b2b, #ff416c);
}
/* end PinkMod */
